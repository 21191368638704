const navbarButton = document.getElementById("navbarDropdownToggle");
const navbarDropdown = document.getElementById("navbarDropdown");

if (navbarButton && navbarDropdown) {
  navbarButton.addEventListener("click", () => {
    navbarDropdown.classList.toggle("hidden");
  });
}

// Navbar search
const searchBar = document.getElementById("search_bar");

const debounce = (callback, delay) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};

if (searchBar) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  searchBar.addEventListener(
    "keyup",
    debounce((e) => {
      const searchQuery = e.target.value;

      if (params.get("lpg") === "true") {
        window.location.href = `/cars?query=${searchQuery}&lpg=true`;
      }else{
        window.location.href = `/cars?query=${searchQuery}`;
      }
    }, 500)
  );
}

// Load current search query to search bar
const currentQuery = new URLSearchParams(window.location.search).get("query");
if (currentQuery) {
  searchBar.value = currentQuery;
}
