const confirmableButtons = document.querySelectorAll("[data-confirmation]");

const confirmationModal = document.getElementById("confirmation-modal");
const confrimationTitle = document.getElementById("confirmation-modal-title");
const cancelButton = document.getElementById("confirmation-modal-cancel");
const confirmButton = document.getElementById("confirmation-modal-confirm");

confirmableButtons.forEach((button) => {
  button.addEventListener("click", (event) => {
    event.preventDefault();
    const confirmationContent = button.dataset.confirmation;

    confirmationModal.classList.remove("hidden");
    confrimationTitle.innerText = confirmationContent;
    confirmButton.innerText = confirmationContent;

    cancelButton.addEventListener("click", () => {
      confirmationModal.classList.add("hidden");
    });

    confirmButton.addEventListener("click", () => {
      confirmationModal.classList.add("hidden");
      event.target.closest("form").submit();
    });
  });
});
