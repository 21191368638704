const url = new URL(window.location.href);
let params = new URLSearchParams(url.search);

// Auto select workshop and state filters
document.addEventListener("DOMContentLoaded", () => {
  const searchParam = new URLSearchParams(window.location.search);
  const workshopParam = searchParam.get("workshop");
  const stateParam = searchParam.get("state");

  if (workshopParam) {
    const workshopInput = document.getElementById(workshopParam);
    workshopInput.checked = true;
  } else {
    const defaultInput = document.getElementById("wernyhory");
    defaultInput.checked = true;
  }

  if (stateParam) {
    const stateInput = document.getElementById(stateParam);
    stateInput.checked = true;
  } else {
    const defaultInput = document.getElementById("all");
    defaultInput.checked = true;
  }
});

// Index filters handlers
const sheetEntryFilters = document.getElementById("sheet-entries-filters-js");

if (sheetEntryFilters) {
  const workshopRadios = document.querySelectorAll('input[name="selected_workshop"]');
  const stateRadios = document.querySelectorAll('input[name="selected_state"]');

  workshopRadios.forEach((radio) => {
    radio.addEventListener("change", function () {
      if (this.checked) {
        params.set("workshop", this.id);
        window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
      }
    });
  });

  stateRadios.forEach((radio) => {
    radio.addEventListener("change", function () {
      if (this.checked) {
        params.set("state", this.id);
        window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
      }
    });
  });
}

// Form
const carSheetForm = document.getElementById("car_sheet_form");

if (carSheetForm) {
  const carSelect = document.getElementById("sheet_entry_car_id");
  const titleInput = document.getElementById("sheet_entry_title");

  carSelect.addEventListener("change", () => {
    const value = carSelect.value === "" ? "" : carSelect.options[carSelect.selectedIndex].text;

    titleInput.value = value;
  });
}

// Sheet entries notes opening
const sheetEntryOpenNote = document.querySelectorAll(".sheet-entry-open-note-js");

if (sheetEntryOpenNote) {
  sheetEntryOpenNote.forEach((openButton) => {
    openButton.addEventListener("click", () => {
      const note = openButton.nextElementSibling;

      if (note && note.classList.contains("hidden")) {
        note.classList.remove("hidden");
      }
    });
  });
}

// Sheet entries notes closing
const sheetEntryNoteClose = document.querySelectorAll(".sheet-entry-note-close-js");

if (sheetEntryNoteClose) {
  sheetEntryNoteClose.forEach((closeButton) => {
    closeButton.addEventListener("click", () => {
      const note = closeButton.closest(".sheet-entry-note-js");
      if (note && !note.classList.contains("hidden")) {
        note.classList.add("hidden");
      }
    });
  });
}
