const lpgFilterCheckbox = document.getElementById("js-lpg-filter");
const vinFilterCheckbox = document.getElementById("js-vin-filter");

// Set the checkbox to checked if the param is present in the URL
document.addEventListener("DOMContentLoaded", () => {
  if (new URLSearchParams(window.location.search).get("lpg")) {
    lpgFilterCheckbox.checked = true;
  }

  if (new URLSearchParams(window.location.search).get("vin")) {
    vinFilterCheckbox.checked = true;
  }
});

// Handle the checkbox click
const handleFilterParams = (checkbox, paramName) => {
  checkbox.addEventListener("click", (e) => {
    const isChecked = e.target.checked;
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if (isChecked) {
      params.set(paramName, "true");
    } else {
      params.delete(paramName);
    }

    window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
  });
};

if (lpgFilterCheckbox || vinFilterCheckbox) {
  handleFilterParams(lpgFilterCheckbox, "lpg");
  handleFilterParams(vinFilterCheckbox, "vin");
}

// Cars sort buttons
const sortButtons = document.querySelectorAll(".js-cars-sort");

sortButtons.forEach((button) => {
  button.addEventListener("click", () => {
    const sortType = button.dataset.sort;

    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if (params.get("order_column") === sortType) {
      params.set("order", params.get("order") === "asc" ? "desc" : "asc");
    } else {
      params.set("order_column", sortType);
      params.set("order", "asc");
    }

    window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
  });
});

const currentSortType = new URLSearchParams(window.location.search).get("order_column");

if (currentSortType) {
  const currentSortButton = document.querySelector(`[data-sort="${currentSortType}"]`);
  currentSortButton.classList.add("bg-gray-200", "text-gray-900");

  const currentSortOrder = new URLSearchParams(window.location.search).get("order");

  if (currentSortOrder === "asc") {
    const svg = currentSortButton.querySelector("svg");
    svg.classList.add("rotate-180");
  } else {
    const defaultSortButton = document.querySelector(`[data-sort="updated_at"]`);
    defaultSortButton.classList.add("bg-gray-200", "text-gray-900");
  }
}
